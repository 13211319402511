import React, { useEffect, useState } from "react";
import { menuPhoneIcon, overviewlockIcon } from "../../../assets/images";
import "./overViewMenu.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { IoCloseOutline, IoMenu } from "react-icons/io5";
import { RiExpandUpDownLine } from "react-icons/ri";
import { getSectionName, toCamelCase } from "../../../utils/commonUtils";
import { t } from "i18next";

const OverViewMenuComponent = ({ getPhoneReportDetails, number }) => {
  const [open, setOpen] = useState(false);
  const [unLockIcon, setUnLockIcon] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const unlockSections =
      getPhoneReportDetails?.getPhoneReportDetailsData?.data?.unlocked;
    const array = [];
    if (unlockSections?.length > 0) {
      for (let i = 0; i < unlockSections?.length; i++) {
        array.push(getSectionName(unlockSections[i]));
      }
    }
    if (
      getPhoneReportDetails?.getPhoneReportDetailsData?.data
        ?.pdf_download_status
    ) {
      array.push("Download");
    }
    setUnLockIcon(["Phone GPS Location", "Monitoring", ...array]);
  }, [getPhoneReportDetails?.getPhoneReportDetailsData?.data]);
  // -> New sections
  const menuItems = [
    { name: t("PR_STEP_3_TITLE_3"), key: "Phone GPS Location" },
    { name: t("PR_PREMIUM_SECTION_TITLE_1"), key: "General Phone Information" },
    { name: t("PR_REPORT_POPUP_TEXT_4"), key: "Phone Reputation Score" },
    { name: t("PR_PHONE_OWNER_INFORMATION"), key: "Phone Owner Information" },
    { name: t("PR_EMAIL_ADDRESS"), key: "Email Addresses" },
    { name: t("PR_REPORT_POPUP_TEXT_6"), key: "Other Phone Numbers" },
    {
      name: t("PR_PHONE_APPROXIMATE_LOCATION"),
      key: "Phone Approximate Location",
    },
    { name: t("PR_POSSIBLE_PHOTOS"), key: "Possible Photos" },
    { name: t("PR_SOCIAL_PROFILES"), key: "Social Profiles" },
    { name: t("PR_PREMIUM_SECTION_TITLE_2"), key: "Work History" },
    { name: t("PR_EDUCATIONAL_BACKGROUND"), key: "Educational Background" },
    { name: t("PR_ADDRESS_HISTORY"), key: "Address History" },
    {
      name: t("PR_PREMIUM_SECTION_TITLE_4"),
      key: "Relationships & Associates",
    },
    { name: t("PR_PREMIUM_SECTION_TEXT"), key: "Name & Ancestry" },
    { name: t("PR_PREMIUM_SECTION_TITLE_5"), key: "Data Leak Checker" },
    // { name: t("PR_OTHER_INFORMATION"), key: "Other Information" },
    { name: t("PR_NUISANCE_CALL"), key: "Nuisance Call Detection" },
    { name: t("PR_NEIGHBOUR_SPOOF"), key: "Neighbour spoofing" },
    { name: t("PR_SEARCH_VOLUME"), key: "Search volume" },
    { name: t("PR_MONITORING"), key: "Monitoring" },
    { name: t("PR_DOWNLOAD"), key: "Download" },
  ];

  // const unLockIcon = [
  //   "Phone GPS Location",
  //   "General Phone Information",
  //   "Phone Reputation Score",
  //   "Nuisance Call Detection",
  //   "Neighbour Spoofing",
  //   "Search Volume",
  // ];

  // -> Old sections
  // const menuItems = [
  //   "Phone GPS Location",
  //   "Unlock Full Report",
  //   "Phone Reputation Score",
  //   "Phone Type",
  //   "Possible Photos",
  //   "Possible Jobs",
  //   "Possible Education",
  //   "Email Addresses",
  //   "Name & Ancestry",
  //   "Possible Associates",
  //   "Neighbour Spoofing",
  //   "Search Volume",
  //   "Other Phone Numbers",
  //   "Phone Approximate Location",
  //   "Social Profiles",
  //   "Address History",
  //   "Data Leak Checker",
  //   "Other",
  // ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigateOverViewMenu = (sectionName) => {
    // setActiveMenuItem(sectionName);
    const camelCaseSectionName = toCamelCase(sectionName);
    const section = document.getElementById(camelCaseSectionName);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    let queryString = location.search;
    const newUrl = location.pathname + (queryString ? queryString : "");
    navigate(`${newUrl}`);
    setOpen(false);
  };

  return (
    <div className="premium-menu-width" id="overview">
      <div className="menu_bg_wrap desktop-menu">
        <div className="overview_menu_head">
          <span>
            <a href="tel:+1 (819) 674-0000">
              <img src={menuPhoneIcon} alt="" />{" "}
              {number ? number : "+1 (819) 674-0000"}
            </a>
          </span>
        </div>
        <div className="overview_menu-title">
          {menuItems.map((item) => (
            <div
              key={item.key}
              className={`menu_item`}
              onClick={() => navigateOverViewMenu(item.key)}
            >
              <Link to={""}>
                {item.name}
                {!unLockIcon.includes(item.key) && (
                  <img src={overviewlockIcon} alt="" />
                )}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={`menu_bg_wrap menu-mobile ${open ? "active" : ""}`}>
        <div onClick={showDrawer} className="fixed-mobile-menu">
          <div className="mobile-active-menu">
            <IoMenu className="mobile-menu-icon" />
            <p>{t("PR_OVERVIEW")}</p>
          </div>
          <RiExpandUpDownLine className="mobile-menu-updown-icon" />
        </div>
        <Drawer
          title=""
          placement={"bottom"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"bottom"}
          className="sidebar-mobile-menu"
        >
          <div className="menu-head-mobile" onClick={onClose}>
            <IoCloseOutline />
            <p>{t("PR_CLOSE_MENU")}</p>
          </div>
          <div className="overview_menu-title menu-title-mobile">
            {menuItems.map((item) => (
              <div
                key={item.key}
                className={`menu_item`}
                onClick={() => navigateOverViewMenu(item.key)}
              >
                <Link to={""}>
                  {item.name}{" "}
                  {!unLockIcon.includes(item.key) && (
                    <img src={overviewlockIcon} alt="" />
                  )}
                </Link>
              </div>
            ))}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default OverViewMenuComponent;
