import React, {useEffect, useState} from "react";
import "./phoneReportMenu.scss";
import {Link} from "react-router-dom";
import {
  dashboardIcon,
  lockIcon,
  pdfIcon,
  researchIcon
} from "../../../assets/images";

import {PhoneReportPayModal} from "../../partials";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {subscriptionStatus} from "../../../redux/slice/subscriptionStatusSlice";
import Loader from "../loader/loader";
import Notify from "../../../../components/common/Notify/notify";
import MultiActionModal from "../../partials/Modals/multiActionModal";
import {pdfSubscriptionStatus} from "../../../redux/slice/pdfSubscriptionStatusSlice";
import {getPdfUrl} from "../../../redux/slice/getPdfUrlSlice";
import {useDownloadReport} from "../../../hooks/useDownloadReport";
import {Cookies} from "react-cookie";
import MonitorSwitch from "../../partials/noInfoFound/monitorSwitch";

const PhoneReportMenu = (props) => {
  const {
    // API and state
    callCheckSubscriptionStatus,
    phoneReportDetails,
    pricingTitleList
  } = props;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const {t} = useTranslation();
  const {isPhoneReport, phoneReport} = phoneReportDetails || {};
  const {handleDownloadReport, PdfActionModal} = useDownloadReport();

  const [payModalOpen, setPayModalOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [mode, setMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [pricingData, setPricingData] = useState({});

  useEffect(() => {
    if (pricingTitleList) {
      setPricingData({
        label_1: pricingTitleList.sections["unlock_all_section"].amount,
        label_2: pricingTitleList.subscriptions["unlock_full_report"].amount,
        symbol: pricingTitleList.sections["unlock_all_section"].symbol
      });
    }
  }, [pricingTitleList]);

  const handleOpenPayModal = async (mode, data) => {
    setIsLoading(true);
    const res = await callCheckSubscriptionStatus("unlock_full_report");
    if (res.type === "subscriptionStatus/fulfilled") {
      const {subscription, usages, canceled} = res.payload.data || {};
      if (!subscription && canceled) {
        setActionModal(true);
        setModalType("renew_subscription");
      } else if (!subscription) {
        setSubscriptionData(data);
        setPayModalOpen(true);
        setMode(mode);
      } else if (subscription && usages) {
        setActionModal(true);
        setModalType("open_report");
      } else if (subscription && !usages) {
        setActionModal(true);
        setModalType("more_report_subscription");
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <MultiActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        modalType={modalType}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        subscription={"unlock_full_report"}
      />
      <PhoneReportPayModal
        isOpen={payModalOpen}
        onClose={() => setPayModalOpen(false)}
        subscriptionData={subscriptionData}
        mode={mode}
        pricingData={pricingData}
      />
      {PdfActionModal}
      <div className="ds_full_reportMenu menu_item_wrap">
        <div className="main_container">
          <div className="menu_item_box_wrap">
            {isPhoneReport ? (
              <div className="menu_item_btn">
                <p>
                  <Link
                    to="javascript:void(0)"
                    data-title="Unlock Full Report"
                    className="menu_button"
                    onClick={() =>
                      handleOpenPayModal("full_report", {
                        title: t("PR_STEP_3_TITLE_1"),
                        description: "PR_UNLOCK_POPUP_DESCRIPTION",
                        label1: "PR_UNLOCK_POPUP_TEXT_1",
                        label2: "PR_UNLOCK_POPUP_TEXT_2",
                        reports: "PR_UNLOCK_POPUP_REPORTS"
                      })
                    }
                  >
                    <img src={lockIcon} alt="" /> {t("PR_STEP_3_TITLE_1")}
                  </Link>
                </p>
                <p>
                  <Link
                    to={"javascript:void(0)"}
                    data-title="PDF"
                    className="menu_button"
                    onClick={() => handleDownloadReport(phoneReport?._id)}
                  >
                    <img src={pdfIcon} alt="" /> <span>{t("PR_DOWNLOAD")}</span>{" "}&nbsp;
                    {t("PR_PDF")}
                  </Link>
                </p>
                <p>
                  <Link
                    to="javascript:void(0)"
                    data-title="Report"
                    className="menu_button"
                  >
                    <p className="monitor__switch_wrap">
                      <MonitorSwitch />
                    </p>
                    {t("PR_MONITOR")} <span>{t("PR_REPORT")}</span>
                  </Link>
                </p>
                <p>
                  <Link
                    to={`/${lang}/dashboard`}
                    data-title="Search other Number"
                    className="menu_button"
                  >
                    <img src={researchIcon} alt="" /> {t("PR_SEARCH_BUTTON")}
                  </Link>
                </p>
                <p>
                  <Link
                    to={`/${lang}/dashboard`}
                    data-title="Dashboard"
                    className="menu_button"
                  >
                    <img src={dashboardIcon} alt="" /> {t("PR_DASHBOARD")}
                  </Link>
                </p>
              </div>
            ) : (
              <div className="menu_item_btn menu2_item_box_wrap">
                <p>
                  <Link
                    to={`/${lang}/dashboard`}
                    data-title="Search other Number"
                    className="menu_button"
                  >
                    <img src={researchIcon} alt="" /> {t("PR_SEARCH_BUTTON")}
                  </Link>
                </p>
                <p className="left_button">
                  <Link
                    to={`/${lang}/dashboard`}
                    data-title="Dashboard"
                    className="menu_button"
                  >
                    <img src={dashboardIcon} alt="" /> {t("PR_DASHBOARD")}
                  </Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callPdfSubscriptionStatus: (data) => dispatch(pdfSubscriptionStatus(data)),
    callGetPdfUrl: (data) => dispatch(getPdfUrl(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneReportMenu);
