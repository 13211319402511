import React, { useState } from "react";
import ownersDetailsIcon from "../../../assets/newhome/ownersDetailsIcon.svg";
import relationshipsAssociatesIcon from "../../../assets/newhome/relationshipsAssociatesIcon.svg";
import contactInfoIcon from "../../../assets/newhome/contactInfoIcon.svg";
import socialMediaIcon from "../../../assets/newhome/socialMediaIcon.svg";
import locationIcon from "../../../assets/newhome/locationIcon.svg";
import possiblePhotosIcon from "../../../assets/newhome/possiblePhotosIcon.svg";
import educationBackgroundIcon from "../../../assets/newhome/educationBackgroundIcon.svg";
import otherInformationIcon from "../../../assets/newhome/otherInformationIcon.svg";
import ownersDetailsImage from "../../../assets/newhome/ownersDetailsImage.svg";
import possiblePhotosImg1 from "../../../assets/newhome/possiblePhotosImg1.svg";
import possiblePhotosImg2 from "../../../assets/newhome/possiblePhotosImg2.svg";
import possiblePhotosImg3 from "../../../assets/newhome/possiblePhotosImg3.svg";
import possiblePhotosImg4 from "../../../assets/newhome/possiblePhotosImg4.svg";
import ownersDetailsPhone from "../../../assets/newhome/ownersDetailsPhone.png";
import stolenDevicesIcon from "../../../assets/newhome/stolenDevicesIcon.svg";
import texCallerIcon from "../../../assets/newhome/texCallerIcon.svg";
import CallersIcon from "../../../assets/newhome/CallersIcon.svg";
import phoneScamsIcon from "../../../assets/newhome/phoneScamsIcon.svg";
import keepSafeIcon from "../../../assets/newhome/keepSafeIcon.svg";
import verifyIdentityIcon from "../../../assets/newhome/verifyIdentityIcon.svg";
import secReverslyReportImg from "../../../assets/newhome/secReverslyReportImg.svg";
import upIcon from "../../../assets/newhome/upIcon.svg";
import downIcon from "../../../assets/newhome/downIcon.svg";
import jobTitleIcon from "../../../assets/newhome/jobTitleIcon.svg";
import dataBreachesIcon from "../../../assets/newhome/dataBreachesIcon.svg";
import businessIcon from "../../../assets/newhome/businessIcon.svg";
import companyIBMIcon from "../../../assets/newhome/companyIBMIcon.svg";
import avatarProfileImag from "../../../assets/newhome/avatarProfileImag.svg";
import mileyProfileIcon from "../../../assets/newhome/mileyProfileIcon.svg";
import mileyAddreshIcon from "../../../assets/newhome/mileyAddreshIcon.svg";
import ctaMarkIcon from "../../../assets/newhome/ctaMarkIcon.svg";
import mainInstagramIcon from "../../../assets/newhome/mainInstagramIcon.svg";
import mainLinkedInIcon from "../../../assets/newhome/mainLinkedInIcon.svg";
import mainFacebookIcon from "../../../assets/newhome/mainFacebookIcon.svg";
import checkEmailIcon from "../../../assets/newhome/checkEmailIcon.svg";
import checkIcon from "../../../assets/newhome/checkIcon.svg";
import repeatGirlIcon from "../../../assets/newhome/repeatGirlIcon.png";
import numberInformationIcon from "../../../assets/newhome/numberInformationIcon.svg";
import securityInvestigationsIcon from "../../../assets/newhome/securityInvestigationsIcon.svg";
import emailAddressesIcon from "../../../assets/newhome/emailAddressesIcon.svg";
import familySafetyIcon from "../../../assets/newhome/familySafetyIcon.svg";
import businessSecurityIcon from "../../../assets/newhome/businessSecurityIcon.svg";
import photosLinkedImg2 from "../../../assets/newhome/photosLinkedImg2.svg";
import photosLinkedImg3 from "../../../assets/newhome/photosLinkedImg3.svg";
import photosLinkedImg4 from "../../../assets/newhome/photosLinkedImg4.svg";
import ctaPatternImg1 from "../../../assets/newhome/ctaPatternImg1.svg";
import ctaPatternImg2 from "../../../assets/newhome/ctaPatternImg2.svg";
import ctaPatternImg3 from "../../../assets/newhome/ctaPatternImg3.svg";
import ctaPatternImg4 from "../../../assets/newhome/ctaPatternImg4.svg";
import effortlessTimeSavingIcon from "../../../assets/newhome/effortlessTimeSavingIcon.svg";
import fastEfficientIcon from "../../../assets/newhome/fastEfficientIcon.svg";
import advancedFiltersIcon from "../../../assets/newhome/advancedFiltersIcon.svg";
import accordionIconBr1 from "../../../assets/newhome/accordionIconBr1.svg";
import accordionIconBr2 from "../../../assets/newhome/accordionIconBr2.svg";
import accordionIconBr3 from "../../../assets/newhome/accordionIconBr3.svg";
import accordionIconBr4 from "../../../assets/newhome/accordionIconBr4.svg";
import accordionIconBr5 from "../../../assets/newhome/accordionIconBr5.svg";
import accordionIconBr6 from "../../../assets/newhome/accordionIconBr6.svg";
import accordionIconBr7 from "../../../assets/newhome/accordionIconBr7.svg";
import accordionIconBr8 from "../../../assets/newhome/accordionIconBr8.svg";

import NewSlickSlider from "../../newslickslider/newSlickSlider";
import PhoneInputComponent from "../../common/phoneInputComponent/phoneInputComponent";
import { t } from "i18next";
const HomePageSection = () => {
  const [collapseTab, setCollapseTab] = useState(0);
  const [FaqsTab, setFaqsTab] = useState(0);
  return (
    <>
      {/*****************************************************/}
      <div className="Sec_reversly__wrapper padding_wrap pb-0 bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <h2 className="SectionMainTitle">{t("PR_PHONE_REPORT_PROVIDE")}</h2>
          </div>
          <div className="reversly__box_wrapper d__desktop_block">
            <div className="reversly__box_info">
              <div className="reversly__box1 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={ownersDetailsIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_OWNER_DETAILS")}</h3>
                    <ul>
                      <li>{t("PR_FULL_NAME")}</li>
                      <li>{t("PR_POSSIBLE_ASSOCIATES_AGE")}</li>
                      <li>{t("PR_GENDER")}</li>
                    </ul>
                  </div>
                  <div className="box__reversly_image">
                    <img src={ownersDetailsImage} alt="" />
                  </div>
                </div>
              </div>
              <div className="reversly__box2 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={relationshipsAssociatesIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_PREMIUM_SECTION_TITLE_4")}</h3>
                    <ul>
                      <li>{t("PR_FAMILY")}</li>
                      <li>{t("PR_COLLEAGUE")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="reversly__box_info">
              <div className="reversly__box3 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={contactInfoIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_CONTACT_INFO")}</h3>
                    <ul>
                      <li>{t("PR_PHONE_NO")}</li>
                      <li>{t("PR_EMAIL_ADDRESS")}</li>
                      <li>{t("PR_SOCIAL_PROFILES")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box4 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={socialMediaIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_SOCIAL_MEDIA")}</h3>
                    <ul>
                      <li>{t("PR_SOCIAL_ACCOUNTS")}</li>
                      <li>{t("PR_DATING_PROFILES")}</li>
                      <li>{t("PR_PHOTOS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="reversly__box_info">
              <div className="reversly__box5 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={locationIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_LOCATION_LOWER")}</h3>
                    <ul>
                      <li>{t("PR_CURRENT_ADDRESS")}</li>
                      <li>{t("PR_PAST_ADDRESS")}</li>
                      <li>{t("PR_MORE_CAP")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box6 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={possiblePhotosIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_POSSIBLE_PHOTOS")}</h3>
                  </div>
                  <div className="box__reversly_image wpb__reversly_image">
                    <img src={possiblePhotosImg1} alt="" />
                    <img src={possiblePhotosImg2} alt="" />
                    <img src={possiblePhotosImg3} alt="" />
                    <img src={possiblePhotosImg4} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="reversly__box_info">
              <div className="reversly__box7 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={educationBackgroundIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_EDUCATION_BG")}</h3>
                    <ul>
                      <li>{t("PR_EDUCATION_DETAILS")}</li>
                      <li>{t("PR_NAME_OF_COLLEGE")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box8 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={otherInformationIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_OTHER_INFORMATION")}</h3>
                    <ul>
                      <li>{t("PR_PREMIUM_SECTION_TEXT")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_5")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_2")}</li>
                      <li>{t("PR_MORE_CAP")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="reversly__box_wrapper phone_box_wrapper d__desktop_none">
            <div class="reversly__box_info">
              <div className="reversly__box1 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="wpb__box_inner">
                    <div className="box__reversly_icon">
                      <img src={ownersDetailsIcon} alt="" />
                    </div>
                    <div className="box__reversly_content">
                      <h3>{t("PR_OWNER_DETAILS")}</h3>
                      <ul>
                        <li>{t("PR_FULL_NAME")}</li>
                        <li>{t("PR_POSSIBLE_ASSOCIATES_AGE")}</li>
                        <li>{t("PR_GENDER")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="box__reversly_image">
                    <img src={ownersDetailsPhone} alt="" />
                  </div>
                </div>
              </div>
              <div className="reversly__box2 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={relationshipsAssociatesIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_PREMIUM_SECTION_TITLE_4")}</h3>
                    <ul>
                      <li>Jasmine Perez</li>
                      <li>John McLoud</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box3 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={contactInfoIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_CONTACT_INFO")}</h3>
                    <ul>
                      <li>{t("PR_PHONE_NO")}</li>
                      <li>{t("PR_EMAIL_ADDRESS")}</li>
                      <li>{t("PR_SOCIAL_PROFILES")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box6 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={possiblePhotosIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_POSSIBLE_PHOTOS")}</h3>
                  </div>
                  <div className="box__reversly_image wpb__reversly_image">
                    <img src={possiblePhotosImg1} alt="" />
                    <img src={possiblePhotosImg2} alt="" />
                    <img src={possiblePhotosImg3} alt="" />
                    <img src={possiblePhotosImg4} alt="" />
                  </div>
                </div>
              </div>
              <div className="reversly__box4 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={socialMediaIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_SOCIAL_MEDIA")}</h3>
                    <ul>
                      <li>{t("PR_SOCIAL_ACCOUNTS")}</li>
                      <li>{t("PR_DATING_PROFILES")}</li>
                      <li>{t("PR_PHOTOS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box5 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={locationIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_LOCATION_LOWER")}</h3>
                    <ul>
                      <li>{t("PR_CURRENT_ADDRESS")}</li>
                      <li>{t("PR_PAST_ADDRESS")}</li>
                      <li>{t("PR_CO_RESIDENTS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box7 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={educationBackgroundIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_EDUCATION_BG")}</h3>
                    <ul>
                      <li>{t("PR_EDUCATION_DETAILS")}</li>
                      <li>{t("PR_NAME_OF_COLLEGE")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box8 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img src={otherInformationIcon} alt="" />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_OTHER_INFORMATION")}</h3>
                    <ul>
                      <li>{t("PR_PREMIUM_SECTION_TEXT")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_5")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_2")}</li>
                      <li>{t("PR_MORE_CAP")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="section__faqs_wrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="ReverslyLookupHelp__area">
            <div className="ReverslyLookupHelp__img">
              <div class="full-report-box">
                <div class="bg-pattern">
                  <div class="line"></div>
                  <div class="pattern-box h-small"></div>
                  <div class="pattern-box"></div>
                  <div class="pattern-box mt-0"></div>
                </div>
                <div class="box-full-report">
                  <h3>{t("PR_FULL_REPORT")}</h3>
                  <div class="inner-check-list">
                    <ul class="report-check-list-box mt-small d-none-row">
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_OWNER_INFO")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_COMPANIES_JOBS")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_ASSOCIATES")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_ADDRESS_HISTORY")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_POSSIBLE_PHOTOS")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item h">
                        <li>
                          <a href="">{t("PR_OTHER_NUMBERS")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                    </ul>
                    <ul class="report-check-list-box mt-small">
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_SOCIAL_PROFILES")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_ADDRESS_HISTORY")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("LOGIN_EMAIL_LABEL")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item m-h">
                        <li>
                          <a href="">{t("PR_MORE_CAP")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item h">
                        <li>
                          <a href="">{t("PR_RELATIONSHIPS")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item h">
                        <li>
                          <a href="">{t("PR_DATA_LEAK")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                      <div class="list-item h mw-h">
                        <li>
                          <a href="">{t("PR_MORE_CAP")}</a>
                        </li>
                        <div class="check-box">
                          <img src={checkIcon} alt="check-icon" />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="email-box">
                  <h4>{t("LOGIN_EMAIL_LABEL")}</h4>
                  <div class="email-inner-box">
                    <div class="email-match">
                      <img src={checkEmailIcon} alt="Email Check Icon" />
                      <h5>{t("PR_MATCH_EMAIL")}</h5>
                    </div>
                    <div>
                      <h3 class="h">sabrine_mont1254@gmail.com</h3>
                      <h4>sabrine_mont1254@....</h4>
                    </div>
                    <div class="email-type-box">
                      <h3 class="e-type">
                        <p class="h">{t("PR_EMAIL_TYPE")}:</p>
                      </h3>
                      <p>{t("PR_PERSONAL")}</p>
                    </div>
                  </div>
                </div>
                <div class="owner-photo-box">
                  <h3>Photos linked to number owner</h3>
                  <div class="inner-photos-box">
                    <div class="photo-box">
                      <img src={repeatGirlIcon} alt="p-1" />
                    </div>
                    <div class="photo-box">
                      <img src={photosLinkedImg2} alt="p-1" />
                    </div>
                    <div class="photo-box h">
                      <img src={photosLinkedImg3} alt="p-1" />
                    </div>
                    <div class="photo-box h">
                      <img src={photosLinkedImg4} alt="p-1" />
                    </div>
                  </div>
                </div>
                <div class="social-profile-box">
                  <h4>{t("PR_SOCIAL_PROFILES")}</h4>
                  <ul class="social-media">
                    <li class="media-box">
                      <div class="icon">
                        <img src={mainFacebookIcon} alt="Facebook Icon" />
                      </div>
                      <a href="#">@Sabrina Montier</a>
                    </li>
                    <li class="media-box">
                      <div class="icon">
                        <img src={mainLinkedInIcon} alt="LinkedIn Icon" />
                      </div>
                      <a href="#">@Sabrina J. Montier</a>
                    </li>
                    <li class="media-box">
                      <div class="icon">
                        <img src={mainInstagramIcon} alt="Instagram Icon" />
                      </div>
                      <a href="#">@Sabi_4598</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion__wrapper">
              <div className="section__title">
                <h2 className="SectionMainTitle text-left">
                  {t("PR_LOOKUP_HELP_HEADER")}
                </h2>
              </div>
              <div className="accordion__item">
                <div
                  class={`accordion-body ${collapseTab === 1 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 1 ? 0 : 1)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 1 ? <img src={texCallerIcon} alt="" /> : <img src={accordionIconBr1} alt="" />
                        }

                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_1")}</h3>
                    </div>
                    {collapseTab === 1 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_1")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class={`accordion-body ${collapseTab === 2 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 2 ? 0 : 2)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 2 ? <img src={accordionIconBr2} alt="" /> : <img src={CallersIcon} alt="" />
                        }
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_2")}</h3>
                    </div>
                    {collapseTab === 2 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_2")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class={`accordion-body ${collapseTab === 3 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 3 ? 0 : 3)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 3 ? <img src={accordionIconBr3} alt="" /> : <img src={phoneScamsIcon} alt="" />
                        }
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_3")}</h3>
                    </div>
                    {collapseTab === 3 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_3")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class={`accordion-body ${collapseTab === 4 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 4 ? 0 : 4)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 4 ? <img src={accordionIconBr4} alt="" /> : <img src={keepSafeIcon} alt="" />
                        }
                      </div>
                      <h3>Keep Loved Ones Safe</h3>
                    </div>
                    {collapseTab === 4 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_4")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class={`accordion-body ${collapseTab === 5 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 5 ? 0 : 5)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 5 ? <img src={accordionIconBr5} alt="" /> : <img src={verifyIdentityIcon} alt="" />
                        }
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_5")}</h3>
                    </div>
                    {collapseTab === 5 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_5")}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="ReverslyReport__section padding_wrap pb-0 bg-white">
        <div className="NewContainer">
          <div className="wpb__ReverslyReport">
            <div className="accordion__wrapper">
              <div className="section__title">
                <h2 className="SectionMainTitle text-left">
                  {t("PR_SEARCH_ADVANTAGES")}
                </h2>
              </div>
              <div className="accordion__item">
                <div
                  class={`accordion-body ${collapseTab === 6 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 6 ? 0 : 6)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 6 ? <img src={effortlessTimeSavingIcon} alt="" /> : <img src={accordionIconBr8} alt="" />
                        }
                      </div>
                      <h3>{t("PR_ADVANTAGES_TITLE_1")}</h3>
                    </div>
                    {collapseTab === 6 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_ADVANTAGES_DESCRIPTION_1")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class={`accordion-body ${collapseTab === 7 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 7 ? 0 : 7)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 7 ? <img src={accordionIconBr7} alt="" /> : <img src={fastEfficientIcon} alt="" />
                        }
                      </div>
                      <h3>{t("PR_ADVANTAGES_TITLE_2")}</h3>
                    </div>
                    {collapseTab === 7 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_ADVANTAGES_DESCRIPTION_2")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class={`accordion-body ${collapseTab === 8 && "accordion-body-open"
                    }`}
                  onClick={() => setCollapseTab(collapseTab === 8 ? 0 : 8)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {
                          collapseTab === 8 ? <img src={accordionIconBr6} alt="" /> : <img src={advancedFiltersIcon} alt="" />
                        }
                      </div>
                      <h3>{t("PR_ADVANTAGES_TITLE_3")}</h3>
                    </div>
                    {collapseTab === 8 && (
                      <div
                        className="accordion__content"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{t("PR_ADVANTAGES_DESCRIPTION_3")}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ReverslyReport__img">
              <img src={secReverslyReportImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="Sec_help__wrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <h2 className="SectionMainTitle">{t("PR_HOW_HELP")}</h2>
          </div>
          <div className="HelpSectionBoxWrpper">
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox1 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img src={stolenDevicesIcon} alt="" />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_1")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_1")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox2 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img src={numberInformationIcon} alt="" />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_2")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_2")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox3 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img src={securityInvestigationsIcon} alt="" />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_3")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_3")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox4 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img src={emailAddressesIcon} alt="" />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_4")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_4")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox5 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img src={familySafetyIcon} alt="" />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_5")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_5")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox6 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img src={businessSecurityIcon} alt="Stolen Devices Logo" />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_6")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_6")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="SectionSliderWrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <h2
              className="SectionMainTitle text-left"
              dangerouslySetInnerHTML={{ __html: t("PR_REAL_REVIEWS") }}
            />
          </div>
        </div>
        <div className="SlickSliderWrapper">
          <NewSlickSlider />
        </div>
      </div>
      {/*****************************************************/}
      <div className="sec__faqs__wrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <h2 className="SectionMainTitle">{t("PR_NEED_TO_KNOW")}</h2>
          </div>
          <div className="faqs_accordion">
            <div className="faqs_accordion_area">
              <div
                class={`faqs_accordion-body ${FaqsTab === 1 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 1 ? 0 : 1)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_1")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 1 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 1 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_1")}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${FaqsTab === 2 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 2 ? 0 : 2)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_2")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 2 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 2 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_2")}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${FaqsTab === 3 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 3 ? 0 : 3)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_3")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 3 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 3 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_3")}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${FaqsTab === 4 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 4 ? 0 : 4)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_4")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 4 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 4 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_4")}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${FaqsTab === 5 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 5 ? 0 : 5)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_5")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 5 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 5 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_5")}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${FaqsTab === 6 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 6 ? 0 : 6)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_6")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 6 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 6 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_6")}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${FaqsTab === 7 && "faqs_accordion-body-open"
                  }`}
                onClick={() => setFaqsTab(FaqsTab === 7 ? 0 : 7)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_7")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 7 ? (
                        <div className="faqs_accordion_up">
                          <img src={upIcon} alt="Minus" />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img src={downIcon} alt="Plus" />
                        </div>
                      )}
                    </div>
                  </div>
                  {FaqsTab === 7 && (
                    <div className="faqs_accordion__content">
                      <p>{t("PR_KNOW_DESCRIPTION_7")}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="CallToAction__wrapper bg-white">
        <div className="NewContainer">
          <div className="CallToAction__Content">
            <div className="section__title">
              <h2 className="SectionMainTitle">{t("PR_SEARCH_NOW")}</h2>
              <p className="TitleDescription">{t("PR_AVAILABLE_INFO")}</p>
              <PhoneInputComponent />
            </div>
            <div className="cta__wpb_content_element">
              <div class="cta__content_element">
                <div class="wpb__details_info">
                  <div class="wpb__user_image">
                    <img src={avatarProfileImag} alt="" />
                  </div>
                  <div class="wpb__presonal_text">
                    <h4 class="wpb__name_details">
                      s.miley@ibm.com <img src={ctaMarkIcon} alt="" />
                    </h4>
                    <div class="wpb__address__info">
                      <ul>
                        <li>
                          <img src={mileyProfileIcon} alt="" /> Miley Smith
                        </li>
                        <li>
                          <img src={mileyAddreshIcon} alt="" /> Atlanta, US
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="wpb__presonal_info">
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_PHONE_LOWER")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      212-587-8898
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_STEP_3_STATUS")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity wpb__inner_status">
                      • {t("PR_ACTIVE")}
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_EMAIL_TYPE_LOWER")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img src={businessIcon} alt="Business Logo" />{" "}
                      {t("PR_BUSINESS")}
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_DATA_BREACHES")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img src={dataBreachesIcon} alt="Data Breaches Logo" /> 5
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_COMPANY")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img src={companyIBMIcon} alt="Company IBM Logo" /> IBM
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_JOB_TITLE_LOWER")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img src={jobTitleIcon} alt="jobTitleIcon" />{" "}
                      {t("PR_SENIOR")}
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width"><img src={ctaPatternImg1} alt="" /></div>
                    <div class="vc_custom_width"><img src={ctaPatternImg2} alt="" /></div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width"><img src={ctaPatternImg3} alt="" /></div>
                    <div class="vc_custom_width"><img src={ctaPatternImg4} alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageSection;
