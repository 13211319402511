import React, { useEffect, useState } from "react";
import { renderValue, toCamelCase } from "../../../../utils/commonUtils";
import { t } from "i18next";
import "./PhoneOwnerInformation.scss";
import { fullUnlockReportImg } from "../../../../assets/images";
import PhoneReportPayModal from "../../Modals/phoneReportPayModal/phoneReportPayModal";
import { useSelector } from "react-redux";
import SectionAction from "../../sectionAction/sectionAction";
import NoInfoFound from "../../noInfoFound/noInfoFound";

const PhoneOwnerInformation = ({
  isLocked,
  sectionName,
  reportDetail,
  number,
}) => {
  const pricingTitleList = useSelector(
    (state) => state.pricingTitleList?.getPhoneReportDetailsData?.data
  );

  const [payModal, setPayModal] = useState(false);
  const [pricingData, setPricingData] = useState({});

  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const { data, status } = phone_owner_info || {};

  useEffect(() => {
    if (pricingTitleList && isLocked) {
      const phoneOwnerInfoPricing =
        pricingTitleList.sections["phone_owner_info"];
      const unlockAllSectionPricing =
        pricingTitleList.sections["unlock_all_section"];
      setPricingData({
        label_1: phoneOwnerInfoPricing.amount,
        label_2: unlockAllSectionPricing.amount,
        symbol: phoneOwnerInfoPricing.symbol,
      });
    }
  }, [pricingTitleList, isLocked]);

  const isDataAvailable = status === "found";

  return (
    <>
      {!isLocked && status === "not_found" && (
        <NoInfoFound
          sectionName={sectionName}
          id={toCamelCase("Phone Owner Information")}
        />
      )}
      <div
        className="unlock_premium_sec white-bg-wrap"
        id={toCamelCase("Phone Owner Information")}
      >
        <div className="ds--unlock__title left-content main_title toggle_area">
          <h2>{t("PR_PHONE_OWNER_INFORMATION")}</h2>
          {!isLocked && <SectionAction />}
        </div>

        <div className="phone-owner-wrap">
          {[
            {
              label: t("NAME"),
              value: isDataAvailable ? renderValue(data?.name) : "Mahesh",
            },
            {
              label: t("PR_AGE"),
              value: isDataAvailable ? renderValue(data?.age) : 38,
            },
            {
              label: t("PR_GENDER"),
              value: isDataAvailable ? renderValue(data?.gender) : "Male",
            },
            {
              label: t("LANGUAGE"),
              value: isDataAvailable ? renderValue(data?.language) : "English",
            },
            {
              label: t("PR_ORIGIN_COUNTRY"),
              value: isDataAvailable
                ? renderValue(data?.country_origin)
                : "India",
            },
          ].map(({ label, value }, index) => (
            <div className="phone-owner-box" key={index}>
              <span>{label}</span>
              <h3 className={isLocked ? "blurred" : ""}>{value}</h3>
              <hr />
            </div>
          ))}
        </div>

        {isLocked && (
          <button className="unlock-btn" onClick={() => setPayModal(true)}>
            <span>{t("PR_UNLOCK_THIS_SECTION").toUpperCase()}</span>
            <div className="unlock-btn-icon">
              <img src={fullUnlockReportImg} alt="Unlock" />
            </div>
          </button>
        )}

        <PhoneReportPayModal
          isOpen={payModal}
          onClose={() => setPayModal(false)}
          sectionName={sectionName}
          mode="section"
          title={t("PR_PHONE_OWNER_INFORMATION")}
          pricingData={pricingData}
          number={number}
        />
      </div>
    </>
  );
};

export default PhoneOwnerInformation;
