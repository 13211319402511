import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import "./newSlickSlider.scss";
import williamDavisImg from "../../assets/newhome/williamDavisImg.svg";
import robertJohnsonImg from "../../assets/newhome/robertJohnsonImg.svg";
import richardThompsonImg from "../../assets/newhome/richardThompsonImg.svg";
import miaTaylorImg from "../../assets/newhome/miaTaylorImg.svg";
import sophiaHernandezImg from "../../assets/newhome/sophiaHernandezImg.svg";
import { Cookies } from "react-cookie";

const NewSlickSlider = () => {
  const { t } = useTranslation();  // Importing the translation hook
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 2;
  const cookie = new Cookies();
  const lang = cookie.get("lang");

  const testimonials = [
    {
      name: t("PR_REVIEWER_NAME_1"),
      text: `“${t("PR_REVIEWER_REVIEW_1")}”`,
      image: williamDavisImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_2"),
      text: `“${t("PR_REVIEWER_REVIEW_2")}”`,
      image: robertJohnsonImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_3"),
      text: `“${t("PR_REVIEWER_REVIEW_3")}”`,
      image: richardThompsonImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_4"),
      text: `“${t("PR_REVIEWER_REVIEW_4")}”`,
      image: miaTaylorImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_5"),
      text: `“${t("PR_REVIEWER_REVIEW_5")}”`,
      image: sophiaHernandezImg,
      rating: 5,
    },
    {
      name: t("PR_REVIEWER_NAME_5"),
      text: `“${t("PR_REVIEWER_REVIEW_5")}”`,
      image: sophiaHernandezImg,
      rating: 5,
    },
  ];

  const totalPages = Math.ceil(testimonials.length / itemsToShow);

  const goToPage = (pageIndex) => {
    console.log("pageIndex",pageIndex)
    setCurrentIndex(pageIndex);
  };
const isRTL = lang === "ac" || lang === "heb" || lang === "ar" || lang === "he" ? true : false
  return (
    <div className="testimonial-slider">
      <div className="testimonial--btn--wrap">
        <div className="dots-container">
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <span
              key={pageIndex}
              className={`dot ${pageIndex === currentIndex ? "active" : ""}`}
              onClick={() => goToPage(pageIndex)}
            ></span>
          ))}
        </div>
      </div>
      <div className="testimonial-container">
        <div
          className="testimonial-row"
          style={isRTL ? { transform: `translateX(${currentIndex * 20}%)` }:{ transform: `translateX(-${currentIndex * 20}%)` }}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="testimonial-header">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="testimonial-image"
                />
                <div className="testimonial-rating">
                  {Array(testimonial.rating)
                    .fill()
                    .map((_, i) => (
                      <span key={i}>⭐</span>
                    ))}
                </div>
                <h3>{testimonial.name}</h3>
              </div>
              <div className="testimonial-body">
                <p>{testimonial.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewSlickSlider;
