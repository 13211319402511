import React from "react";
import { emailpdfIcon, pdfFileIcon, printIcon } from "../../../../assets/images";
import "./downloadSection.scss";
import { generatePdfBlob, toCamelCase } from "../../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import Notify from "../../../../../components/common/Notify/notify";
import { connect } from "react-redux";
import { uploadPdf } from "../../../../redux/slice/uploadPdfSlice";
import { sendPdfEmail } from "../../../../redux/slice/sendPdfEmailSlice";
import { useDownloadReport } from "../../../../hooks/useDownloadReport";

const DownloadSection = (props) => {
  const {
    setPdfLoading,
    // API and state
    phoneReportDetails,
    callSendPdfEmail,
    callUploadPdf,
  } = props;

  const { t } = useTranslation();
  const { phoneReport } = phoneReportDetails || {};
  const { handleDownloadReport, PdfActionModal } = useDownloadReport();

  const handleSendEmail = async () => {
    try {
      setPdfLoading(true);
      if (phoneReport?.status === "locked") {
        Notify(
          "error",
          "Please unlock at least one section to Email this report",
          ""
        );
        setPdfLoading(false);
        return;
      }
      let res = await callSendPdfEmail(phoneReport?._id);
      if (res.type === "sendPdfEmail/fulfilled") {
        Notify("success", res.payload.message || t("PR_PDF_EMAIL_SUCCESS"), "");
        return;
      }
      if (res.error?.message === "The PDF URL is not available.") {
        const pdfBlob = await generatePdfBlob("main_root");
        const formData = new FormData();
        formData.append("pdfFile", pdfBlob, "report.pdf");
        formData.append("phone_report_id", phoneReport?._id);

        res = await callUploadPdf(formData);
        if (res.type === "uploadPdf/fulfilled") {
          res = await callSendPdfEmail(phoneReport?._id);
          if (res.type === "sendPdfEmail/fulfilled") {
            Notify(
              "success",
              res.payload.message || t("PR_PDF_EMAIL_SUCCESS"),
              ""
            );
          } else {
            Notify("error", res.error?.message, "");
          }
        } else {
          Notify("error", res.error?.message, "");
        }
      } else {
        Notify("error", res.error?.message, "");
      }
    } catch (error) {
      Notify("error", error.message, "");
    } finally {
      setPdfLoading(false);
    }
  };
  return (
    <>
            {PdfActionModal}

      <div
        className="monitor__ds_btn ds--monitor-report white-bg-wrap"
        id={toCamelCase("Download")}
      >
        <div className="monitor-cmp-wrap">
          <img src={pdfFileIcon} alt="" />
          <h3>{t("PR_DOWNLOAD_SECTION_TITLE")}</h3>
          <p>{t("PR_DOWNLOAD_SECTION_DESCRIPTION")}</p>
          <div className="button-container">
            <button
              className="info-button"
              onClick={() => handleDownloadReport(phoneReport?._id)}
            >
              <img src={printIcon} alt="" />
              <span>
                {"  "} {t("PR_DOWNLOAD_PDF")}
              </span>
            </button>
            <button className="info-button" onClick={handleSendEmail}>
              <img src={emailpdfIcon} alt="" />
              <span>
                {"  "} {t("PR_EMAIL_PDF")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callUploadPdf: (data) => dispatch(uploadPdf(data)),
    callSendPdfEmail: (data) => dispatch(sendPdfEmail(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadSection);
