import React, { useEffect, useState, useMemo, useCallback } from "react";
import { t } from "i18next";
import {
  locationIcon,
  nodataIcon,
  possiblePhotosImg,
} from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import {
  DEFAULT_CENTER,
  mapOptions,
  renderValue,
  toCamelCase,
} from "../../../utils/commonUtils";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { googleAPIKey } from "../../../../environment";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const PhoneApproximateLocation = ({ isLocked, sectionName, reportDetail }) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: googleAPIKey });
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [time, setTime] = useState(new Date());
  const { phone_approximate_location } = reportDetail?.phoneReport || {};
  const { data, status } = phone_approximate_location || {};
  const [locationData, setLocationData] = useState({
    timezone: "",
    areaCode: "",
    timezoneName: "",
  });

  const locationDataArray = data?.[0] || {};
  const { location_name: address = "Unknown Location", location_geo } =
    locationDataArray;

  const parseLatLng = useCallback(
    (geoString) => geoString?.split(",").map(Number) || [null, null],
    []
  );
  const [latitude, longitude] = useMemo(
    () => parseLatLng(location_geo),
    [location_geo, parseLatLng]
  );

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({ lat: latitude, lng: longitude });
      setIsInfoWindowOpen(false);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!isLocked && status === "found") {
      const timerId = setInterval(() => {
        setTime(new Date());
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, []);

  useEffect(() => {
    const fetchLocationData = async () => {
      const geocoderUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleAPIKey}`;
      const timezoneUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(
        Date.now() / 1000
      )}&key=${googleAPIKey}`;

      try {
        const geocoderResponse = await fetch(geocoderUrl);
        const geocoderData = await geocoderResponse.json();

        if (geocoderData.results && geocoderData.results.length > 0) {
          const areaCode =
            geocoderData.results[0].address_components[0].long_name;

          const timezoneResponse = await fetch(timezoneUrl);
          const timezoneData = await timezoneResponse.json();

          if (timezoneData.status === "OK") {
            const timezone = timezoneData.timeZoneId;
            const timezoneName = timezoneData.timeZoneName;

            setLocationData({
              timezone,
              areaCode,
              timezoneName,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocationData();
  }, [latitude, longitude]);

  const handleMapClick = useCallback(() => {
    setIsInfoWindowOpen(false);
    setCenter({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${formattedMinutes}${ampm}`;
  };

  if (isLocked) {
    return (
      <UnlockReport
        title={t("PR_PHONE_APPROXIMATE_LOCATION")}
        image={possiblePhotosImg}
        buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
        shouldKnowText={t("PR_UNLOCK_TITLE")}
        knownText={t("PR_APPROXIMATE_LOCATION_MESSAGE")}
        sectionName={sectionName}
        pricingSectionName="phone_approximate_location"
        id={"Phone Approximate Location"}
      />
    );
  }
  return (
    <>
      {!isLocked && status === "not_found" ? (
        <NoInfoFound
          sectionName={sectionName}
          id={toCamelCase("Phone Approximate Location")}
        />
      ) : (
        <div
          className="ds__phn--location white-bg-wrap"
          id={toCamelCase("Phone Approximate Location")}
        >
          <div className="trs__bg_phone">
            <div className="unlock_title unlock-description toggle_area download_menu_option">
              <h2>{t("PR_PHONE_APPROXIMATE_LOCATION")}</h2>
              <SectionAction />
            </div>
            <div className="ds--job-wrapper">
              {status === "found" && (
                <p>
                  {locationData.areaCode &&
                    t("PR_AREACODE_INFO", {
                      areaCode: locationData.areaCode,
                      timezone: locationData.timezone,
                    })}
                  &nbsp;
                  {locationData.timezone &&
                    locationData.timezoneName &&
                    t("PR_TIMEZONE_INFO", {
                      timezone: locationData.timezone,
                      timezoneName: locationData.timezoneName,
                    })}
                  &nbsp;
                  {t("PR_CURRENTTIME_INFO", { time: formatTime(time) })}
                </p>
              )}
            </div>
            {status === "found" && latitude && longitude ? (
              <div className="phone__location_map">
                <div className="ds_location__info">
                  <div className="location_map__icon">
                    <img src={locationIcon} alt="Location Icon" />
                  </div>
                  <div className="location_map__text" onClick={handleMapClick}>
                    <span>{renderValue(address)}</span>
                  </div>
                </div>

                <div className="ds_location__map">
                  <div
                    className="map-container"
                    style={{
                      height: "300px",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                  >
                    {isLoaded && (
                      <GoogleMap
                        zoom={15}
                        center={center}
                        mapContainerStyle={{ height: "100%", width: "100%" }}
                        options={mapOptions}
                      >
                        <MarkerF
                          key={`${latitude}-${longitude}`}
                          position={{ lat: latitude, lng: longitude }}
                          onClick={() => setIsInfoWindowOpen(true)}
                        >
                          {isInfoWindowOpen && (
                            <InfoWindowF
                              onCloseClick={() => setIsInfoWindowOpen(false)}
                            >
                              <p>{address}</p>
                            </InfoWindowF>
                          )}
                        </MarkerF>
                      </GoogleMap>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p className="data_notfound">
                <img src={nodataIcon} alt="No Data Icon" />{" "}
                {t("PR_RECORD_NOT_FOUND")}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PhoneApproximateLocation;
